.my-masonry-grid {
  display: flex;
  margin-left: -10px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 10px; /* gutter size */
  background-clip: padding-box;
}

/* grid items */
.my-masonry-grid_column > a {
  display: block;
  margin-bottom: 10px;

  img {
    width: 100%;
    opacity: .9;
    transition: all .2s;

    &:hover {
      opacity: 1;
      transform: scale(1.04);
    }
  }

  &.current {
    .img-thumbnail {
      opacity: 1;
      transform: scale(1.04);
      background-color: #ffc107;
    }
  }
}

.modal-90w {
    width: 100%;
    max-width: 100vw!important;
    height: 100vh !important;
    margin: 0 auto!important;

    .modal-content {
      height: 100vh !important;
    }

    img {
      max-height: 75vh;
    }

    .modal-title, .by-line > p {
      text-transform: capitalize;
    }

    .imageLoading {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      z-index: 20;
    }

    .arrows {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      z-index: 20;

      > div {
        height: 96px;
        width: 100%;
      }

      .icon {
        color: #ffc107;
      }

      span {
        opacity: 0;

        &:hover {
          cursor: pointer;
        }
      }

      &:hover {
        span {
          opacity: .8;
          &:hover {
            cursor: pointer;
          }
        }
      }

    }
}

